
import CyanPageWrapper, {
  router,
  store,
  defineComponent,
} from "@/components/CyanPageWrapper.vue";
import cyanRequest from "@/modules/cyanRequest";
import CyanForm from "@/components/CyanForm.vue";
import {
  arrowForwardCircleOutline,
  helpCircleOutline,
  warningOutline,
  eyeOutline,
  eyeOffOutline,
} from "ionicons/icons";
import buttonTrigger from "@/modules/cyanButtonTrigger";

import {
  IonButton,
  IonItem,
  IonLabel,
  IonPage,
  IonInput,
  IonIcon,
  IonCheckbox,
} from "@ionic/vue";

import ValidatorSet, {
  validateDUILogin,
  validateRegexp,
} from "@/modules/cyanValidator";

export default defineComponent({
  name: "Login",
  components: {
    CyanPageWrapper,
    CyanForm,
    IonButton,
    IonItem,
    IonPage,
    IonLabel,
    IonIcon,
    IonInput,
    IonCheckbox,
  },
  setup() {
    return {
      arrowForwardCircleOutline,
      helpCircleOutline,
      warningOutline,
      eyeOutline,
      eyeOffOutline,
    };
  },
  computed: {
    router() {
      return router;
    },
  },
  data() {
    return {
      dui: store.state.lastLoggedIn,
      password: "",
      remember: store.state.rememberEnabled,
      validator: null as any,
      passwordVisible: false,
    };
  },
  mounted() {
    this.validator = new ValidatorSet([
      {
        validator: validateDUILogin,
        validatorParams: {
          regexp: ".",
          noMatch: "El nombre de usuario no puede estar vacío.",
        },
        element: ".__login-DUI",
        nextElement: ".__login-password",
      },
      {
        validator: validateRegexp,
        element: ".__login-password",
        nextElement: ".__login-next",
        validatorParams: {
          regexp: ".",
          noMatch: "La contraseña no puede estar vacía.",
        },
      },
    ]);

    buttonTrigger(".__login-next", this.doLogin);
  },
  updated() {
    if (this.validator) this.validator.bind();
    buttonTrigger(".__login-next", this.doLogin);
  },
  methods: {
    toggleViewPassword() {
      this.passwordVisible = !this.passwordVisible;
    },
    async doLogin() {
      if (this.validator) {
        if (!(await this.validator.checkWithAlert())) return;
      }

      const d = await cyanRequest("login", {
        isModal: true,
        isPost: true,
        needsPreflight: false, // !this.$data.remember,
        params: {
          name: this.$data.dui,
          password: this.$data.password,
          requestToken: this.$data.remember ? "1" : "",
        },
      });

      if (!d.ok) return;

      store.commit("userLogin", d);
      router.replace("/home");
    },
  },
});
